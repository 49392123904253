import React from "react";
import { Link } from "react-router-dom";

const HeroSection: React.FC = () => {
  return (
    <div className="hero-curvebody">
      <section className="hero">
        <div className="hero-body">
          <div className="hero-section">
            <video
              playsInline
              loop
              muted
              autoPlay
              preload="metadata"
              className="hero-video"
            >
              <source src="/images/allish_4.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <!-- <svg xmlns="http://www.w3.org/2000/svg" className="wave-svg1" viewBox="0 0 1440 320">
                <path fill="#084C2E " fill-opacity="1" d="M0,0L120,21.3C240,43,480,85,720,85.3C960,85,1200,43,1320,21.3L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
              </svg> --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="wave-svg"
              viewBox="0 0 1440 150"
            >
              {/* <!-- <path fill="#084C2E " fill-opacity="1" d="M0,0L120,21.3C240,43,480,85,720,85.3C960,85,1200,43,1320,21.3L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path> --> */}
              <path
                fill="#084C2E "
                fillOpacity="1"
                d="M 0 50 C 720 250 1440 50 1440 50 L 1440 150 L 0 150 Z"
              ></path>
              {/* <!-- <path fill="#084C2E " fill-opacity="1" d="M0, 50 C950,100 1440,50 1440, 50 L1440,150 L0,150 Z"></path> --> */}
            </svg>
            {/* <!--  --> */}
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <div className="head-con">
                    <div className="hero-title">
                      Your Neighbourhood, Your Shopping Universe.
                    </div>
                    <div className="hero-subtitle">
                      Explore a world of ethnic groceries with Allish. Whether
                      it's specialty items or your everyday essentials, Allish
                      connects you with local ethnic stores across the UK for a
                      seamless shopping experience.
                    </div>
                    <div className="hero-button">
                      <Link to="#appdownload">
                        <button type="button" className="btn shopping-btn">
                          Start Shopping
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
