import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function SmoothScroll() {
  const location = useLocation();

  const getOffset = (id: string) => {
    const headerSize = 92; // Adjust this value to match your header height
    const noOffsetIdList = ["appdownload"];
    return noOffsetIdList.includes(id)
      ? window.innerWidth <= 768
        ? -headerSize
        : 0
      : -headerSize;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (location.hash) {
        const id = location.hash.slice(1);
        const element = document.getElementById(id);
        if (element) {
          const yOffset = getOffset(id);
          const y =
            element.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };

    // Wait for the DOM to fully render before scrolling
    const timeout = setTimeout(handleScroll, 5);

    return () => clearTimeout(timeout); // Cleanup
  }, [location]);

  return null;
}
