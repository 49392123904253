/* eslint-disable react-hooks/exhaustive-deps */
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import React, { useEffect, useRef } from "react";
import animationData from "../Phones-lotti.json";
import { Link } from "react-router-dom";
import { AppStore, PlayStore } from "./Icons";

const PocketSection: React.FC = () => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null); // Ref for controlling the Lottie animation
  const wrapperRef = useRef<HTMLDivElement | null>(null); // Ref for observing visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Play the animation when it enters the viewport
            lottieRef.current?.play();
          } else {
            // Optionally stop the animation when it exits the viewport
            lottieRef.current?.stop();
          }
        });
      },
      { threshold: 0.3 } // Adjust threshold as needed
    );

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, []);

  return (
    <section className="pocket" id="appdownload">
      <div className="container">
        <div className="row align-items-md-center">
          <div className="col-md-5 order-cs-1">
            <div className="pocket-details">
              <div className="pocket-title">
                Allish in Your Pocket Shop Anywhere, Anytime
              </div>
              <div className="pocket-description me-md-3">
                Elevate your grocery shopping experience with the 'Allish UK'
                mobile app. Discover a world of convenience with easy access to
                your favorite stores, now just a tap away. The 'Allish UK' app
                is designed to bring groceries straight to your door, wherever
                you are.
              </div>
              <div className="pocket-apps me-md-3">
                <div className="row">
                  <div className="col-6 col-md-5">
                    <Link
                      to="https://apps.apple.com/gb/app/allish-uk/id6502819288"
                      target="_blank"
                    >
                      {AppStore}
                    </Link>
                  </div>
                  <div className="col-6 col-md-5 ps-0">
                    <Link
                      to="https://play.google.com/store/apps/details?id=allish.co.uk.prod"
                      target="_blank"
                    >
                      {PlayStore}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 order-cs-0">
            <div className="pocket-image">
              <div className="video-tag">
                <div
                  id="lottie-container"
                  className="img-fluid"
                  ref={wrapperRef}
                >
                  <Lottie
                    lottieRef={lottieRef}
                    animationData={animationData}
                    loop={false}
                    autoplay={false} // Start animation manually
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PocketSection;
